/******** client review part start *******/
.client_review{
    background-image: url(../img/review_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 180px 0;
    position: relative;
    z-index: 1;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #fff;
        opacity: .3;
        z-index: -1;
    }
    @media #{$tab}{
        padding: 70px 0;
    }
    @media #{$medium_device}{
        padding: 80px 0;
    }
    h4{
        font-size: 30px;
        line-height: 44px;
        margin-bottom: 15px;
        margin-top: 30px;
        @media #{$tab}{
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 10px;
            margin-top: 15px;
        }
        @media #{$medium_device}{
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 10px;
            margin-top: 15px;
        }
    }
    .singke_client_review{
        text-align: center;
        img{
            border-radius: 50%;
            
            max-width: 80px;
            margin: 0 auto;
        }
    }
    .owl-dots{
        text-align: center;
        margin-top: 20px;
        .owl-dot {
            width: 12px;
            height: 12px;
            background-color: #4D4D4D;
            display: inline-block;
            border-radius: 50%;
            margin: 5px;
        }
        .owl-dot.active {
            background-color: $btn_hover;
        }
    }
}