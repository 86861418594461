$font_stack_1: 'Quicksand', sans-serif;
$font_stack_2: 'Quicksand', sans-serif;

$white_color: #fff;
$black_color: #000;
$btn_bg: #53C9BB;
$base_color: #53C9BB;
$btn_hover: #FF5B5B;
$section_bg: #fff7ef;
$section_bg_1: #454545;
$heading_color: #082B49;
$heading_color2: #ff8b23;
$font_1: #4D4D4D;
$p_color: #4D4D4D;
$font_1: #666666;
$font_2: #777777;
$font_3: #797979;
$font_4: #8a8a8a;
$font_5: #999999;
$font_6: #666666;
$border_color: #fdcb9e;
$footer_bg: #303030;
$sidebar_bg: #fbf9ff;


$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$small_mobile: '(max-width: 576px)';
$tab: '(max-width: 991px)';
$sm_mobile: 'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen: 'only screen and (min-width: 1200px) and (max-width: 1440px)';
  
  
  